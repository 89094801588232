import axios from 'axios';
//import {encodeDataToURL} from "./functions";
import {encodeGeoHash} from "./geohash";
import {translate} from './translate.js'
const h=window.location.hash
let api=''
  if (h==='#alpha') api='https://api.alpha.protone.app/'
  else if (h==='#beta') api='https://api.beta.protone.app/'
  else if (h==='#gamma') api='https://api.gamma.protone.app/'
  else if (h==='#delta') api='https://api.delta.protone.app/'
  else if (h==='#dev') api= 'https://api.development.protone.app/'   
  else api='https://api.ru.protone.app/';//https://api.ru.protone.app/
let setVisibleError=()=>{return false}
export const setError=(handler)=>
{
setVisibleError=handler	
}

//картинки фильтров захардкодены, однако если бек начнет отдавать картинки - то будут отображаться они
export const returnFilterImgByTitle=(title)=>
{
const replace=[
{title:'PLASTIC',img:'/i/filters/UTILIZATION_pet.png'}, //UTILIZATION_plastic.png
{title:'GLASS',img:'/i/filters/UTILIZATION_glass.png'},
{title:'PAPER',img:'/i/filters/UTILIZATION_paper.png'},
{title:'CUPS',img:'/i/filters/UTILIZATION_cap.png'},
{title:'CAPS',img:'/i/filters/UTILIZATION_cap.png'},
{title:'TIRES',img:'/i/filters/UTILIZATION_tire.png'},
{title:'PET',img:'/i/filters/UTILIZATION_pet.png'},
{title:'MIXED',img:'/i/filters/UTILIZATION_mixed.png'},
{title:'METAL',img:'/i/filters/UTILIZATION_metal.png'},
{title:'BATTERIES',img:'/i/filters/UTILIZATION_batteries.png'},
{title:'DANGEROUS',img:'/i/filters/UTILIZATION_dangerous.png'},
{title:'APPLIANCES',img:'/i/filters/UTILIZATION_electric.png'},
{title:'CHRISTMAS_TREES',img:'/i/filters/UTILIZATION_tree.png'},
{title:'CLOTHES',img:'/i/filters/UTILIZATION_cloth.png'},
{title:'SHOES',img:'/i/filters/SOCIAL_clothes.png'},
{title:'LAMPS',img:'/i/filters/UTILIZATION_lamps.png'},
{title:'TETRAPACK',img:'/i/filters/UTILIZATION_tetrapack.png'},
{title:'FUNCTIONAL_OBJECTS',img:'/i/filters/ART_functional.png'},
{title:'SCULPTURE',img:'/i/filters/ART_sculpture.png'},
{title:'PAINTING',img:'/i/filters/ART_hub.png'},
{title:'GRAFFITI',img:'/i/filters/ART_graffiti.png'},
{title:'INFO_CENTER',img:'/i/filters/SOCIAL_info_center.png'},
{title:'MUSEUM',img:'/i/filters/RELAX_museum.png'},
{title:'ALUMINIUM',img:'/i/filters/UTILIZATION_alluminium.png'},
{title:'METAL_COLOURED',img:'/i/filters/UTILIZATION_metal_coloured.png'},
{title:'PETS_HELP_COLLECTION_POINT',img:'/i/filters/pets.png'}
]
  for (var i=0; i<replace.length; i++) if (replace[i].title===title) return replace[i].img;
return title; //если ничего не нашли
}

export const getFilters=(set)=>
{
return new Promise((resolve, reject)=>
{
const data=
{
"provider": "YANDEX"
}
axios({
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
   url:  api+'point-of-interest/filters/all',
  //  url:  'http://localhost:3000/json_new_filter.json',
    data:  data
})
.then(response=> { 
//const resp_arr=JSON.parse(response.data);
const arr=response.data.stats
//const list = Object.keys(arr);
//let rez=[]
  for (let property in arr) 
   {
    for (var i=0; i<arr[property].points.length; i++)
    	arr[property].points[i].img=returnFilterImgByTitle(arr[property].points[i].img)
   }
//console.log(arr)
set(response.data.stats)
//window.setTimeout(()=>resolve(''),100)
resolve('')
})
.catch(function (error) {
set(false)
setVisibleError(translate('Нельзя получить список для фильтрации'),error)
//alert('Что-то пошло не так: '+error)
console.log(error);
reject('')
});
})
}
var cachedGlobalPartners=null; //для кеширования глобальных партнеров на сессию
const getCacheGlobalPartners=(set,filter)=>
{
  if (cachedGlobalPartners!==null && filter===null) //у нас есть кеш глобальных партнеров
   {
let arr=cachedGlobalPartners
for (let property in arr) 
   {
set(arr[property].points); //это кеш для всех партнеров при отсутствии фильтров
break;
   }
set(cachedGlobalPartners) //это кеш для второго экрана при отсутствии фильтров
return true
   }
  if (cachedGlobalPartners!==null) //кеш есть, нужно отфильтровать
   {
    if (filter!==null && filter.length===0)  //фильтры не указаны, возвращаем "как есть"
     {
set(cachedGlobalPartners)  //это кеш для второго экрана 
return true
     }
    else  if (filter!==null && filter.length!==0)
    {
let toFilter=structuredClone(cachedGlobalPartners)
let points=null
let rez=[]
for (let property in toFilter) 
   {
points=toFilter[property].points
    for (var i=0; i<points.length; i++) //проверим каждого партнера
    {
let item=points[i]
      if (item.filterIds.some((element) => filter.includes(element))) //нашли хотя бы один фильтр
       {
rez.push(item)
       }
    }
toFilter[property].points=rez
break;
   }
  if (points===null) return false
set(toFilter)  //это кеш для второго экрана если какие-то фильтры выбраны
return true
    }
   }
return false
}
var ApiGlobalPartnersId=0;
export const getPartners=(set,ids)=>
{
const data=
{
"ids":(ids!==null && ids.length>0)?ids:null
}
ApiGlobalPartnersId++
const currentId=ApiGlobalPartnersId;
  if (getCacheGlobalPartners(set,ids))
   {
 return true //уже есть в кеше, повторно не запрашиваем
   } //ВКЛЮЧИТЬ как будет апи
axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    url: api+'point-of-interest/filters/partners', //http://localhost:3000/json_new_parthers.json
    data:  data
})
.then(response=> { 
//const resp_arr=JSON.parse(response.data);
  if (currentId===ApiGlobalPartnersId) 
   {
cachedGlobalPartners=response.data.stats
set(response.data.stats)
    }
})
.catch(function (error) {
  if (currentId===ApiGlobalPartnersId)
   {
set(false)
setVisibleError(translate('Нельзя получить список для фильтрации партнеров'),error)
   }
//alert('Что-то пошло не так: '+error)
console.log(error);
});
}

export const getAllPartners=(set)=>
{
const data=
{
}
  if (getCacheGlobalPartners(set,null))
   {
 return true //уже есть в кеше, повторно не запрашиваемr)
   } /* ВКЛЮЧИТЬ как будет апи*/
axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    url: api+'point-of-interest/filters/partners', //http://localhost:3000/json_new_parthers.json
    data:  data
})
.then(response=> { 
//const list = Object.keys(response.data.stats);
const arr=response.data.stats
cachedGlobalPartners=arr
  for (let property in arr) 
   {
set(arr[property].points);
break;
   }

})
.catch(function (error) {
set(false)
setVisibleError(translate('Нельзя получить список для фильтрации партнеров'),error)
//alert('Что-то пошло не так: '+error)
console.log(error);
});
}



var cachedGlobalPoints=null; //для кеширования глобальных точек на сессию
const getCacheGlobalPoints=(set,filter,partner,currentId,arrFilters)=>
{
  if (cachedGlobalPoints!==null && filter===null && partner===null) //у нас есть кеш глобальных точек
   {
set(cachedGlobalPoints, currentId)
return true
   }
  if (cachedGlobalPoints!==null) //кеш есть, нужно отфильтровать по критериям
   {
let rez=[]
//const cachedGlobalPointsAr=JSON.parse(JSON.stringify(cachedGlobalPoints))
const cachedGlobalPointsArr=cachedGlobalPoints
 //   if (partner===null) //партнер не задан, чтобы не делать лишнюю проверку в цикле - значимого ускорения не дает, протестировано
//     {
    	for (var i=0; i<cachedGlobalPointsArr.length; i++)
    	 {
      if (partner===null || partner.length===0  || partner.indexOf(cachedGlobalPointsArr[i].partnerId)!==-1)
    	  for (var j=0; j<filter.length; j++)
          if (cachedGlobalPointsArr[i].filterIds.indexOf(filter[j])!==-1)
           {
let item=cachedGlobalPointsArr[i]
//let item=structuredClone(cachedGlobalPointsArr[i]) //можно клонировать переменную, тогда порядок фильтров на глобальной карте не поменяется, но скорость упадет в 5 раз
 for (var m=filter.length; m--; m>0) //фильтры которые выбраны поместим вперед списка
   {
    if (item.filterIds.indexOf(filter[m])!==-1) 
     {
item.filterIds.splice(item.filterIds.indexOf(filter[m]), 1);
item.filterIds.unshift(filter[m])
     }
   }
 /*item.filterIds=item.filterIds.filter(num => !filter.includes(num));
item.filterIds= [...filter,...item.filterIds];*/
const tempIcon=arrFilters.filter(f=>f.filterId===item.filterIds[0]) //иконка первого выбранного и найденного фильтра будет присвоена точке
  if (tempIcon.length>0) item.icon=tempIcon[0].img
rez.push(item)
break
           }
    	 }
//        }
     
set(rez, currentId)
return true
   }
return false
}
var ApiGlobalPointsId=0;
export const getGlobalPoints=(set,filter,partner,arrFilters)=>
{
const data=
{
    "filterIds": (filter==="null")?null:filter,
    "partnerIds": (partner==="null")?null:partner,
    "mergeRadius": 0.2,
   "mergePoints": true /*to merge use true*/
}
ApiGlobalPointsId++
const currentId=ApiGlobalPointsId;
  if (getCacheGlobalPoints(set,filter,partner,currentId,arrFilters))
   {
 return true //уже есть в кеше, повторно не запрашиваемr)
   }
axios({
    method: 'post',
    url: api+'point-of-interest/filtered/point-skeleton',
    headers: { 'Content-Type': 'application/json' },
    /*method: 'get',
    url: './all_ponts.json',*/
    data:  data
})
.then(response=> { 
let resp_arr=[] 
  for(var i in response.data.pointOfInterestList) 
   {
let item=response.data.pointOfInterestList[i]
item.geohash2=encodeGeoHash(item.latitude,item.longitude,4);
    if (item.filterIds.length===0  /*|| item.filterIds.length>1*/) item.icon='/i/filters/UTILIZATION_pet.png';
    else 
     {
item.icon=arrFilters.filter(f=>f.filterId===item.filterIds[0])
  if (item.icon.length>0) item.icon=item.icon[0].img
  else
   {
item.icon='/i/filters/UTILIZATION_pet.png'
console.log('error with point',item,arrFilters)
  }
      }
    	
resp_arr.push(item);
   }
  if (currentId===ApiGlobalPointsId)
   {
    if (cachedGlobalPoints===null && filter===null && partner===null) cachedGlobalPoints=resp_arr //закешируем глобальные точки
   set(resp_arr,currentId)
   }
})
.catch(function (error) {
	  if (currentId===ApiGlobalPointsId)
	   {
set(false,currentId)
          }
setVisibleError(translate('Нельзя получить глобальные точки'),error)
//alert('Что-то пошло не так: '+error)
console.log(error);
});
}

export const getAllNamePoints=(set)=>
{

const data=
{
 "provider": "YANDEX",
 "latitude": 0,
 "longitude": 0,
"radius": 9999999,
 "pointOfInterestTypeId": null,
"localityId": null,
 "mergePoints": true,
 "mergeRadius": 999999
}
axios({
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    url: api+'point-of-interest/all/near-point',
    data:  data
})
.then(response=> { 
let resp_arr=[] 
  for(var i in response.data) resp_arr.push({id:response.data[i].pointOfInterestTypeId,idtopic:response.data[i].topic,name:response.data[i].name,pic:response.data[i].contentUrl});
set(resp_arr)
})
.catch(function (error) {
set(false)
setVisibleError(translate('Нельзя получить имена точек'),error)
//alert('Что-то пошло не так: '+error)
console.log(error);
});
}
export function returnNamebyPointType(arr,id)
{
  for (var i=0; i<arr.length; i++)
   {
    for (var j=0; j<arr[i].points.length; j++)
     {
if (parseInt(arr[i].points[j].pointOfInterestTypeId)===parseInt(id)) return arr[i].points[j].name
     }
   }
return 'Все' //Неизвестный тип точки
}

export const returnPointbyPointType=(arr,id)=>
{

  for (var i=0; i<arr.length; i++)
   {
    for (var j=0; j<arr[i].points.length; j++)
     {
if (parseInt(arr[i].points[j].pointOfInterestTypeId)===parseInt(id)) return arr[i].points[j]
     }
   }
return false
}

var ApiGetPointId=0;
export const getPoint=(set,id,allpoints,mergedIds,selectedFilters)=>
{
const data=
{
  "id": (id==="null")?null:id ,
  "mergedIds": (mergedIds==="null")?null:mergedIds ,
}
//console.log(data)
ApiGetPointId++
const currentId=ApiGetPointId;
let timer=window.setTimeout(()=>set(false),300) //если за 0.3 секунды запрос не вернулся - покажем прелоадер
axios({
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
   /* url: api+'point-of-interest/all/near-point',*/
     url: api+'point-of-interest/point', //http://localhost:3000/json_one_point.json
	data: data
})
.then(response=> { 
let resp_arr=[]
let arrimg=[]
for(var i in response.data.contentUrlList) arrimg.push({"file":response.data.contentUrlList[i],"preview":response.data.contentPreviewUrlList[i]});
//отсортируем по выбранным фильтрам, если они выбраны
let filterIds=response.data.filterIds
//console.log('before', filterIds,'orig is',selectedFilters)
  for (var j=selectedFilters.length; j--; j>0)
   {
    if (filterIds.indexOf(selectedFilters[j])!==-1) 
     {
filterIds.splice(filterIds.indexOf(selectedFilters[j]), 1);
filterIds.unshift(selectedFilters[j])
     }
   }
resp_arr={arrimg:arrimg,description:response.data.description,name:
(response.data.name===null)?translate('Пункт без названия'):response.data.name
,address:(response.data.address)?response.data.address:null
,phone:(response.data.phone)?response.data.phone:null
,work:(response.data.work)?response.data.work:null
,filterIds:(response.data.filterIds)?response.data.filterIds:null
,partnerId:(response.data.partnerId)?response.data.partnerId:null
,latitude:(response.data.latitude)?response.data.latitude:null
,longitude:(response.data.longitude)?response.data.longitude:null
};
 //to debug, remove it when prod!
 /*
resp_arr.arrimg.push({
  "file": "https://storage.yandexcloud.net/s3.ru.protone.app/moderation-approval-file/ce34c575-7f42-403e-9a2f-935509e73623.9j",
  "preview": "https://storage.yandexcloud.net/gd-staging-public/moderation-approval-file/0f7ba448-0d77-4278-9e86-65291426a181.png"
})
 resp_arr.arrimg.push(resp_arr.arrimg[0])
 resp_arr.arrimg.push({
  "file": "https://storage.yandexcloud.net/s3.ru.protone.app/moderation-approval-file/ce34c575-7f42-403e-9a2f-935509e73623.9j",
  "preview": "https://storage.yandexcloud.net/gd-staging-public/moderation-approval-file/0f7ba448-0d77-4278-9e86-65291426a181.png"
})
 resp_arr.arrimg.push(resp_arr.arrimg[0])
 resp_arr.arrimg.push({
  "file": "https://vk.com/video_ext.php?oid=-216090997&id=456239354&hd=2&autoplay=1','preview",
  "preview": "https://storage.yandexcloud.net/gd-staging-public/moderation-approval-file/0f7ba448-0d77-4278-9e86-65291426a181.png"
})
 
 for (let i=-0; i<10; i++)  resp_arr.arrimg.push(resp_arr.arrimg[0])
 
*/
//end for debug
  if (currentId===ApiGetPointId) 
   {
window.clearTimeout(timer)
set(resp_arr)
   }
})
.catch(function (error) {
  if (currentId===ApiGetPointId)
   {
window.clearTimeout(timer)
set(false)
setVisibleError(translate('Нельзя получить данные о точке'),error)
   }
//alert('Что-то пошло не так: '+error)
console.log(error);
});
}