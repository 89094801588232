/*
Module slider by ALaKO 2023-2025
version 17.02.25
swipe:true|false --add support of touch
timer:int|false --add auto-rotate slides, not worked properly
arrows:true|false --add support of arrow left\right to rotate slides
click=twice|once --add support of change slides by mouse (1\2 to prev\next or only next)
cursor=true|false --add support of cursor arrows (recommend with click=twice)
*/
import React from 'react'
import {useState, useEffect, useRef} from 'react'
//import {useDebounce} from './debounce'
import {animateDivScroll} from './functions'
import './bigslider.scss'
var classNames = require('classnames');
var  timer, preloadLeft=new Image(), preloadRight=new Image()

const returnNextImg=(left=false,arr,currentImage)=> //return next, or prev image index
{
return (left?(currentImage+arr.length-1)%arr.length:(currentImage+1)%arr.length)
}

const delay=(x,callback)=> 
{
return new Promise((resolve, reject)=>
  {
setTimeout(() => {callback(); resolve('')}, x)
  });
}

function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}

//thumbnails, optional
export const BigSliderThumbnails=(props)=>
{
const thumbScroll=useRef(null);
const current=props.current
const arrows=(props.arrows)?<div><div className="arrow_left" onClick={()=>props.setcur(returnNextImg(true,props.aimg,current))}>LEFT</div><div className="arrow_right"  onClick={()=>props.setcur(returnNextImg(false,props.aimg,current))}>RIGHT</div></div>:''
useEffect(() => { 
if (thumbScroll.current===null || typeof current==="undefined") return
let scrollTo=thumbScroll.current.childNodes[(current===-1)?0:current].offsetLeft-thumbScroll.current.offsetLeft
  if (scrollTo>thumbScroll.current.scrollWidth-thumbScroll.current.offsetWidth) scrollTo=thumbScroll.current.scrollWidth-thumbScroll.current.offsetWidth
  if (scrollTo<thumbScroll.current.scrollWidth-thumbScroll.current.offsetWidth /*|| thumbScroll.current.scrollLeft===0 */ || current===props.aimg.length-1) 
   {
thumbScroll.current.classList.add('no_animation')
animateDivScroll(thumbScroll.current,scrollTo,1000)
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);

  if (typeof current==="undefined" || typeof props.aimg==='undefined' || props.show===false || props.aimg.length===0) return ''
//prepare thumbnails
let thumbs=[]
  for (var i=0; i<props.aimg.length; i++)
   {
let temp=i
const isVideo=props.aimg[i].file.indexOf('vkvideo.ru')!==-1  || props.aimg[i].file.indexOf('vk.com')!==-1 || props.aimg[i].file.indexOf('youtube.com')!==-1 || props.aimg[i].file.indexOf('rutube.ru')!==-1 || props.aimg[i].file.indexOf('.mp4')!==-1
   thumbs.push(<div className={classNames("image",{"image_act":(current===i)?true:false,"image_video":isVideo})} onClick={(e)=>{(e).stopPropagation();props.setcur(temp)}} key={"thumb_"+i} style={{backgroundImage:`url(${props.aimg[i].preview})`}}></div>)
   }
//
const scroller=(e)=>
{
let scrollTo;
e.preventDefault()
  if (e.deltaY>=-15 && e.deltaY<=15) scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*40);
  else scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*5);
thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "auto"
});
}
return(
<div className="images" ref={thumbScroll} onWheel={(e)=>{if (props.scroll) scroller(e);}}>{thumbs}{arrows}</div>
)
}

const OneImage=(props)=>
{
const [loaded,setLoaded]=useState(false)
useEffect(()=>
{//console.log(props)
setLoaded(false)
  },[/*props.src,props.cls,props*/])
const load=(e)=>
{
setLoaded(true)
  }
let res
  if (props.src.indexOf('.mp4')!==-1) res=<video key={0+"video"} width="100%" height="100%" controls autoPlay><source src={props.src} type="video/mp4" /></video>
  else if (props.src.indexOf('rutube.ru')!==-1)  res=<iframe key={0+"video"} title="video" width="100%" height="100%" src={props.src} autoPlay frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
  else if (props.src.indexOf('youtube.com')!==-1)  res=<iframe key={0+"video"} title="video" width="100%" height="100%" src={props.src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullscreen></iframe>
  else if (props.src.indexOf('vk.com')!==-1 || props.src.indexOf('vkvideo.ru')!==-1)  res=<iframe key={0+"video"} title="video" src={props.src} width="100%" height="100%" frameBorder="0" allowFullscreen="1" allow="autoplay; encrypted-media; fullscreen; picture-in-picture"></iframe>
  else res=<img key={0+"video"} src={props.src} style={{opacity:(loaded)?1:0}} className="one_image" onError={load} onLoad={load} alt="" />
return(
res
)
}
const MyImage=(props)=>
{
const [hide,setHide]=useState(false)
useEffect(() => {
    if (props.prev && (props.src.indexOf('.mp4')!==-1 || props.src.indexOf('rutube.ru')!==-1 || props.src.indexOf('youtube.com')!==-1 || props.src.indexOf('vk.com')!==-1 )) setHide(true)   //if video hide immediately prev block
    else if (props.prev) delay(500,()=>setHide(true)) //hide prev block when animate is stop

// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
return(
(!hide)?<div className={props.cls}><OneImage src={props.src} /></div>:''
)
}
	
export function BigSlider(props)
{
let timerA1,timerA2;
const ImagesPath = ''
const myRef=useRef(null);
const [ignoreCurrent,setIgnoreCurrent]=useState(false)
const [currentImage,setCurrentImage]=useState(-1)
const [curImg,setCurImg]=useState('')
const [ImgCls,setImgCls]=useState('current_image')
const [prevImg,setPrevImg]=useState('')
const [prevImgCls,setPrevImgCls]=useState('hide_image')
const isMobile=(/iphone/.test(navigator.userAgent.toLowerCase())) || (/android/.test(navigator.userAgent.toLowerCase())) // || (/ipad/.test(ua))
const supportTouch=(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
//add custom cursor
const customCursor=(e)=>
{
  if (props.cursor===true)
(e.nativeEvent.clientX/window.innerWidth>=0.5)?myRef.current.className="gallery cursorRight":myRef.current.className="gallery cursorLeft"
}
const customClick=(e)=>
{
(e).stopPropagation()
  if ((isMobile || supportTouch || e.target.tagName==='VIDEO')) return; //disable click for mobile
/*1/2 of screen is left slide, 1/2 is right slide */
  if (props.click==="twice") animateImage((e.nativeEvent.clientX/window.innerWidth>=0.5)?true:false)
/* click at slide = next slide*/
  if (props.click==="once") animateImage(false)
}
//end add custom cursor
//add support of touch
const [touchStart,setTouchStart]=useState('')
const [touchEnd,setTouchEnd]=useState('')
const htouchStart=(e)=>{setTouchStart(e.targetTouches[0].clientX)}
const htouchMove=(e)=>{setTouchEnd(e.targetTouches[0].clientX)}
const htouchEnd=(e)=>
{  
if (props.swipe===false ) return false
  if (Math.abs(touchStart-touchEnd)>50 && touchEnd!==0) 
{
/*clearTimer()
setStopTimer(false)*/
e.stopPropagation()
  if (touchStart-touchEnd>50) animateImage(false)
  else if (touchStart-touchEnd<-50) animateImage(true)
setTouchEnd(0)
setTouchStart(0)
   }
}
//end touch

const checkKey=(e)=> //for left-right navigation by arrow 
{
  if (props.arrows!==true) return false
var event = window.event ? window.event : e
const key=event.keyCode;
  if (key===37 || key===39)
   {
/*clearTimer()
setStopTimer(true)*/
   }
  if (key===37) animateImage(true) //next image when arrow right
  if (key===39) animateImage(false) //prev image when arrow left
  if (key===27) props.close() //close when escape
  if (key===32) animateImage(true) //next image when space
}


const preload=()=> //preload next and prev images
{
preloadLeft=new Image()
preloadRight=new Image()
  if (preloadLeft.src.indexOf('.mp4')!==-1)
preloadLeft.src=ImagesPath+props.arr[returnNextImg(true,props.arr,currentImage)].file
  if (preloadRight.src.indexOf('.mp4')!==-1)
preloadRight.src=ImagesPath+props.arr[returnNextImg(false,props.arr,props.currentImage)].file
}

const openImage=(i)=> //open image and start preloading
{
i=parseInt(i)
  if (typeof props.arr==='undefined' || props.arr===null || props.arr.length===0 || typeof props.arr[i]==='undefined') return false
  if (parseInt(currentImage)!==i) 
   {
setCurImg(ImagesPath+props.arr[i].file)
    setCurrentImage(i)	
    }
preload()
}

const setTimer=()=>
{
  if (typeof props.timer==='undefined' || props.timer===false) return false;
window.clearInterval(timer)
timer=window.setInterval(()=>{animateImage(false)},props.timer)	
}

const animateImage=(left=false)=>
{
  if (props.arr.length<=1) return
setTimer() //clear timer
setIgnoreCurrent(true)
window.clearTimeout(timerA1)
window.clearTimeout(timerA2)
 timerA2=window.setTimeout(function(){setIgnoreCurrent(false);},50)
  if (isMobile)
   {
setPrevImgCls('hide_image disableAnimation');
setImgCls('current_image disableAnimation')
   }

setPrevImg(ImagesPath+props.arr[currentImage].file)
openImage(returnNextImg(left,props.arr,currentImage))
  if (isMobile) timerA1=window.setTimeout(function(){setImgCls('next_image');},20)
  else timerA1=window.setTimeout(function(){setImgCls('next_image');setPrevImgCls('prev_image')},1)
}
 useEvent('keydown', checkKey)
 	 
//init slider

useEffect(() => {
//console.log(currentImage,props.current,ignoreCurrent)
  /*if (currentImage!==props.current || props.current===0) 
{*/
  if (ignoreCurrent) 
  {
setIgnoreCurrent(false)
   }
   else
   { 
   	   
//setImgCls('current_image')
setImgCls('current_image disableAnimation')
setPrevImgCls('hide_image disableAnimation');
//setPrevImg('')
window.setTimeout(()=>{
openImage(props.current)
setImgCls('next_image')
setTimer()},1)
}
//}
//end init slider
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);

useEffect(() => {
//setIgnoreCurrent(true)
  if (props.setArrow)
   props.setArrow({goRight(){animateImage(false)},goLeft(){animateImage(true)}}) // eslint-disable-next-line react-hooks/exhaustive-deps
props.setcur(currentImage)// eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentImage]);

//react �������� �������� ��� ontouch ��������, �� memo, �� ���-�� ������ �������� �� ������. �������� ���. ��������
/*
const render=(isMobile)?<div><div style={{backgroundImage:"url('"+curImg+"')"}} className={ImgCls} ></div>
<div style={{backgroundImage:"url('"+prevImg+"')"}}  className={prevImgCls} ></div></div>
:<div><MyImage src={curImg} cls={ImgCls} cur={currentImage}  /><MyImage src={prevImg} cls={prevImgCls} cur={currentImage} prev={true}  /></div>*/

return (
<div>
<div className="gallery" ref={myRef}   onClick={(e)=>customClick(e)} onMouseMove={(e)=>customCursor(e)} onTouchStart={htouchStart} onTouchEnd={htouchEnd} onTouchMove={htouchMove}>
<div><MyImage src={curImg} cls={ImgCls} cur={currentImage} key={ImgCls+currentImage}  /><MyImage src={prevImg} cls={prevImgCls} cur={currentImage} prev={true} key={prevImgCls+currentImage}  /></div>
</div>
</div>
)
}


export default BigSlider;